import axios from "axios";
const createBackendServer = (baseURL) => {
  
  axios.defaults.withCredentials = true;
  const api = axios.create({
    baseURL: `${baseURL}/api`,
    headers: {
      Accept: "application/json",
      "Access-Control-Allow-Origin": '*'
  },
    timeout: 60 * 1000,
  });
  
  const headers = {
    "Content-Type": "multipart/form-data"
};

api.interceptors.response.use(
  (response) => response,
  (error) => {
      const message = error.response.data;
      error.message = message || error.message
      /*if(error?.response?.data?.errors)
          error.errors = error?.response?.data?.errors;*/
      return Promise.reject(error)
  })

  /***********    GET REQUESTS    **********/
  const getAllUsers = () => api.get(`/admin/user`);
  const getBlogs = () => api.get(`/admin/blogs`);
  
  const getAnalytics = () => api.get(`/admin/analytics`);

  
  /***********    POST REQUESTS    **********/
  
  const addBlog = (body) => api.post(`/admin/blogs`,body);
  

  /***********    PUT REQUESTS    **********/
  
  const editBlogs = (body) => api.put(`/admin/update/blogs`,body);
  
  /***********    Delete REQUESTS    **********/
  
  const   deleteBlog = (id) => api.delete(`/admin/blogs/${id}`);
  const   deleteUser = (id) => api.delete(`/admin/user/${id}`);
  

  return {
    getAllUsers,
    getBlogs,
    deleteBlog,
    editBlogs,
    addBlog,
    deleteUser,
    getAnalytics
  };
};


const apis = createBackendServer(process.env.NODE_ENV === 'development' ? "http://138.197.88.145:5000" : "http://138.197.88.145:5000");


export default apis;
