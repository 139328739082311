import { useState } from 'react';
import './App.css';
import {SideBar,Header} from './components/Index';
import {BrowserRouter as Router,Route,Routes} from 'react-router-dom';
import Blogs from './screens/Blogs';
import Home from './screens/Home';
import Users from './screens/Users';

function App() {
  return (
    <div className="App main-body">
      <Router>
      <SideBar />
      <div className="main-section">
        {/* <Header/> */}
        <Routes>
       
        <Route path="/users" element={<Users/>}  />
        <Route path="/blogs" element={<Blogs/>}  />
        <Route path="/" element={<Home/>}  />
      
        </Routes>
      </div>
      </Router>
    </div>







  );
}

export default App;
