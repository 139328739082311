import React, { useState, useEffect } from 'react'
import { Button, Col,Table, Container, Form, Modal as CustomModal, Row } from "react-bootstrap";

const Modal = ({ show, handleClose, modalTitle, handleSubmit, data, setData ,loader,reqireState }) => {
console.log(data,"DATA")

    useEffect(() => {
        if(modalTitle === 'Create Blog') {
            setData({ image: null, title: '',excerpt:'', description: '', _id: '' })
        }
    },[modalTitle]);

    const onChangeHandler = (e) => {
        const { name, value, files } = e.target;
        if (files) {
            setData(prevData => ({ ...prevData, [name]: files[0] }))
        } else {
            setData(prevData => ({ ...prevData, [name]: value }))
        }
    }


    return (
        <CustomModal show={show} onHide={handleClose} centered className='custom-modal'>
            <CustomModal.Header closeButton>
                <CustomModal.Title className ='text-color'>{modalTitle}</CustomModal.Title>
            </CustomModal.Header>
            <Form onSubmit={(e)=>{
                e.preventDefault() 
                handleSubmit(data)}}>
            <CustomModal.Body>

                <div className="dashboard-box  reward-token-box ">

                    
                        <Form.Group className="mb-3" controlId="formFileSm">
                            <Form.Label className ='text-color'>Image</Form.Label>
                            <Form.Control type="file" accept="image/png, image/gif, image/jpeg"size="sm" className='custom-input' name="image"
                                onChange={onChangeHandler} 
                                required ={reqireState} />
                        </Form.Group>

                        <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                        >
                            <Form.Label className ='text-color'>Blog Title</Form.Label>
                            <Form.Control type="text" name='title' className='custom-input' placeholder="Blog Title" value={data.title}
                                onChange={onChangeHandler} required />
                        </Form.Group>

                        <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                        >
                            <Form.Label className ='text-color'>Blog Excerpt</Form.Label>
                            <Form.Control as="textarea" rows={2} className='custom-input' maxLength={250} type="text" name='excerpt' placeholder="Blog Excerpt"
                                value={data.excerpt} onChange={onChangeHandler}
                                required />
                        </Form.Group>

                        <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                        >
                            <Form.Label className ='text-color'>Blog Description</Form.Label>
                            <Form.Control as="textarea" rows={3} className='custom-input' type="text" name='description' placeholder="Blog Description"
                                value={data.description} onChange={onChangeHandler}
                                required />
                        </Form.Group>

                </div>
            </CustomModal.Body>
            <CustomModal.Footer>
                {/* <button className='custom-btn secondary-btn round-btn' style={{backgroundColor:'white' ,color:'black', border:'none'}} onClick={handleClose}>
                    Close
                </button> */}

                <button className='custom-btn round-btn' type='submit' disabled={loader}>
                 {modalTitle}   
                </button>
            </CustomModal.Footer>
            </Form>
        </CustomModal>

    )
}

export default Modal;